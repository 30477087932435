import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IBusinessType } from "../businesstypeModel";

export const getAllBusinessType = createAsyncThunk(
  "BusinessType/FindAllBusinessType",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/BusinessType/FindAllBusinessType`,
        {
          BusinessType: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching BusinessType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveBusinessType = createAsyncThunk(
  "BusinessType/FindAllActiveBusinessType",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/BusinessType/FindAllActiveBusinessType`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching BusinessType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createBusinessType = createAsyncThunk(
  "BusinessType/InsertBusinessType",
  async (BusinessTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/BusinessType/InsertBusinessType`,
        BusinessTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating BusinessType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateBusinessType = createAsyncThunk(
  "BusinessType/UpdateBusinessType",
  async (BusinessTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/BusinessType/UpdateBusinessType`,
        BusinessTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating BusinessType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getBusinessTypeByID = createAsyncThunk(
  "BusinessType/FindByIDBusinessType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/BusinessType/FindByIDBusinessType`,
        { business_type_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        business_type_guid: result.business_type_guid,
        business_type: result.business_type,

        isactive: result.isactive === true ? 1 : 2,
      } as IBusinessType;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching BusinessType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const businessTypeActiveInactive = createAsyncThunk(
  "BusinessType/ActiveInActiveBusinessType",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/BusinessType/ActiveInActiveBusinessType`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive BusinessType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteBusinessType = createAsyncThunk(
  "BusinessType/DeleteBusinessType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/BusinessType/DeleteBusinessType`,
        { business_type_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting BusinessType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
