import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRole, IRoleInitialstate } from "./roleModel";
import {
  createRole,
  deleteRole,
  getAllActiveRole,
  getAllRole,
  getRoleByID,
  roleActiveInactive,
  updateRole,
} from "./services/role.services";

const initialState: IRoleInitialstate = {
  loading: false,
  formLoading: false,
  error: "",
  RoleList: [],
  RoleID: null,
  RoleDetail: {
    name: "",
    isactive: 1,
  },
};

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    clearRoleDetails: (state) => {
      state.RoleDetail = initialState.RoleDetail;
    },
    setRoleID: (state, action) => {
      state.RoleID = action.payload;
    },
    clearRoleID: (state) => {
      state.RoleID = initialState.RoleID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRole.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllRole.fulfilled,
      (state, action: PayloadAction<IRole[]>) => {
        state.loading = false;
        state.RoleList = action.payload || [];
      }
    );
    builder.addCase(getAllRole.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RoleList = [];
    });
    builder.addCase(createRole.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createRole.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createRole.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateRole.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateRole.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateRole.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteRole.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteRole.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteRole.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getRoleByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getRoleByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.RoleDetail = action.payload;
    });
    builder.addCase(getRoleByID.rejected, (state, action) => {
      state.formLoading = false;
      state.RoleDetail = initialState.RoleDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveRole.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveRole.fulfilled,
      (state, action: PayloadAction<IRole[]>) => {
        state.loading = false;
        state.RoleList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveRole.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RoleList = [];
    });
    builder.addCase(roleActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(roleActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(roleActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearRoleDetails, setRoleID, clearRoleID } = roleSlice.actions;
export default roleSlice.reducer;
