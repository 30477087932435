// import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../components/layout/Layout";

import { Button } from "@progress/kendo-react-buttons";
import LogIn from "../features/login/Login";
import { SuccessToast } from "../components/toast/Toasts";
import Country from "../features/country/Country";
import User from "../features/user/User";
import CreateUser from "../features/user/CreateUser";
import UserType from "../features/userType/UserType";
import CreateUserType from "../features/userType/CreateUserType";
import Rights from "../features/rights/Rights";
import CreateRights from "../features/rights/CreateRights";
import Menu from "../features/menu/Menu";
import CreateMenu from "../features/menu/CreateMenu";
import City from "../features/city/City";
import State from "../features/state/State";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../features/dashboard/Dashboard";
import TypeofService from "../features/typeofservice/TypeofService";
import BusinessType from "../features/businesstype/BusinessType";
import Sector from "../features/sector/Sector";
import Port from "../features/port/Port";
import Department from "../features/department/Department";
import Branch from "../features/branch/Branch";
import Industries from "../features/Industries/Industries";
import LeadType from "../features/leadtype/LeadType";
import LeadSource from "../features/leadsource/LeadSource";
import LeadStatus from "../features/leadstatus/LeadStatus";
import CallType from "../features/calltype/CallType";
import CallOutCome from "../features/calloutcome/CallOutCome";
import ShippingMovements from "../features/shippingmovement/ShippingMovements";
import Role from "../features/role/Role";
import CompanyType from "../features/companytype/CompanyType";
import CompanyCategory from "../features/companycategory/CompanyCategory";

// import CreateCountry from "../features/country/CreateCountry";

const MainRouter = () => {
  // const dispatch = useAppDispatch();
  return (
    <Routes>
      <Route path="login" element={<LogIn />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        <Route index={true} element={<Dashboard />} />
        <Route path="country" element={<Country />} />
        <Route path="city" element={<City />} />
        <Route path="state" element={<State />} />
        <Route path="user" element={<User />} />
        <Route path="typeofservice" element={<TypeofService />} />
        <Route path="businesstype" element={<BusinessType />} />
        <Route path="sector" element={<Sector />} />
        <Route path="port" element={<Port />} />
        <Route path="department" element={<Department />} />
        <Route path="branch" element={<Branch />} />
        <Route path="industries" element={<Industries />} />
        <Route path="leadtype" element={<LeadType />} />
        <Route path="leadsource" element={<LeadSource />} />
        <Route path="leadstatus" element={<LeadStatus />} />
        <Route path="role" element={<Role/>}/>
        <Route path="companytype" element={<CompanyType/>}/>
        <Route path="shippingmovements" element={<ShippingMovements />} />
        <Route path="calltype" element={<CallType />} />
        <Route path="calloutcome" element={<CallOutCome />} />
        <Route path="companycategory" element={<CompanyCategory/>}/>
        <Route path="user/create" element={<CreateUser />} />
        <Route path="user/edit" element={<CreateUser />} />
        <Route path="usertype" element={<UserType />} />
        <Route path="usertype/create" element={<CreateUserType />} />
        <Route path="usertype/edit" element={<CreateUserType />} />
        <Route path="rights" element={<Rights />} />
        <Route path="rights/create" element={<CreateRights />} />
        <Route path="rights/edit" element={<CreateRights />} />
        <Route path="menu" element={<Menu />} />
        <Route path="menu/create" element={<CreateMenu />} />
        <Route path="menu/edit" element={<CreateMenu />} />

        {/* <Route path="country/create" element={<CreateCountry />} />
        <Route path="country/edit" element={<CreateCountry />} /> */}
        <Route
          path="about"
          element={
            <Button
              fillMode={"solid"}
              themeColor={"primary"}
              onClick={() => SuccessToast("Hello")}
              // onClick={() => dispatch(openDialog("testDialog"))}
            >
              Hello
            </Button>
          }
        />
        <Route path="*" element={<h1>404</h1>} />
      </Route>
    </Routes>
  );
};

export default MainRouter;
