import React from "react";
import "./login.scss";
// import gif from "../../assets/Images/6sg.gif";
// import logo from "../assets/images/connectorlogo.svg";
import {
  Form,
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import FormPasswordField from "../../components/formFields/FormPasswordField";
import {
  passwordValidator,
  requiredValidator,
} from "../../components/formFields/CommonValidator";
// import ConnectorLogo from "../../assets/Images/connectorlogo.svg";
import { useNavigate } from "react-router-dom";
import { employeeLogin } from "./loginSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
// import truck from "../../assets/Images/truck.png"
import globizzlogo from "../../assets/Images/Globizz logo (1).png";
// import stop from "../../assets/Images/stop.png"
import ButtonWithLoading from "../../components/common/ButtonWithLoading";

const LogIn: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loading = useAppSelector((state) => state.login.loading);

  const handleSubmit = async (values: any) => {
    const data = {
      user_name: values.UserName,
      password: values.Password,
    };
    const response = await dispatch(employeeLogin(data));
    if (response?.meta?.requestStatus === "fulfilled") {
      navigate("/");
    }
  };
  return (
    <>
      <div className="main-container">
        <div className="logincontainer">
          <img src={globizzlogo} className="globizzlogo" />

          <div className="screen">
            <div className="screen__content">
              <Form
                onSubmit={handleSubmit}
                initialValues={{
                  UserName: "",
                  Password: "",
                  RememberMe: false,
                }}
                render={(formRenderProps: FormRenderProps) => (
                  <FormElement className="login">
                    <div className="login__field">
                      <h2>
                        Login <span> here</span>
                      </h2>
                      <Field
                        name={"UserName"}
                        type={"text"}
                        className="login__input"
                        // class="login__input"
                        component={FormTextField}
                        label={"User Name"}
                        validator={requiredValidator}
                        astrike={true}
                      />
                    </div>
                    {/* <div className="login__field"> */}
                    <Field
                      name={"Password"}
                      type={"password"}
                      className="login__input"
                      component={FormPasswordField}
                      label={"Password"}
                      astrike={true}
                      validator={passwordValidator}
                    />
                    {/* </div> */}

                    <ButtonWithLoading
                      label="Login"
                      themeColor="primary"
                      type="submit"
                      className="button login__submit"
                      width="100%"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                  </FormElement>
                )}
              />
            </div>

            <div className="screen__background">
              {/* <span className="screen__background__shape screen__background__shape4"></span> */}
              <span className="screen__background__shape screen__background__shape3"></span>
              <span className="screen__background__shape screen__background__shape2"></span>
              <span className="screen__background__shape screen__background__shape1"></span>
            </div>
          </div>
          {/* <img src={truck} className="vector" /> */}
          {/* <img src={stop} className="stop-vector" /> */}
        </div>
      </div>
    </>
  );
};

export default LogIn;
