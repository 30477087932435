import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICalloutCome, ICalloutcomeState } from "./calloutcomeModel";
import {
  callOutcomeActiveInactive,
  createCallOutcome,
  deleteCallOutcome,
  getAllActiveCallOutcome,
  getAllCallOutcome,
  getCallOutcomeByID,
  updateCallOutcome,
} from "./services/calloutcome.services";

const initialState: ICalloutcomeState = {
  loading: false,
  formLoading: false,
  error: "",
  CalloutcomeList: [],
  CalloutcomeID: null,
  CalloutcomeDetail: {
    call_outcome: "",
    isactive: 1,
  },
};

const calloutcomeSlice = createSlice({
  name: "calloutcome",
  initialState,
  reducers: {
    clearCallOutcomeDetails: (state) => {
      state.CalloutcomeDetail = initialState.CalloutcomeDetail;
    },
    setCallOutcomeID: (state, action) => {
      state.CalloutcomeID = action.payload;
    },
    clearCallOutcomeID: (state) => {
      state.CalloutcomeID = initialState.CalloutcomeID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCallOutcome.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllCallOutcome.fulfilled,
      (state, action: PayloadAction<ICalloutCome[]>) => {
        state.loading = false;
        state.CalloutcomeList = action.payload || [];
      }
    );
    builder.addCase(getAllCallOutcome.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CalloutcomeList = [];
    });
    builder.addCase(getAllActiveCallOutcome.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveCallOutcome.fulfilled,
      (state, action: PayloadAction<ICalloutCome[]>) => {
        state.loading = false;
        state.CalloutcomeList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveCallOutcome.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CalloutcomeList = [];
    });
    builder.addCase(createCallOutcome.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createCallOutcome.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createCallOutcome.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateCallOutcome.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateCallOutcome.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateCallOutcome.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteCallOutcome.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteCallOutcome.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCallOutcome.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(callOutcomeActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(callOutcomeActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(callOutcomeActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getCallOutcomeByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getCallOutcomeByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.CalloutcomeDetail = action.payload;
    });
    builder.addCase(getCallOutcomeByID.rejected, (state, action) => {
      state.formLoading = false;
      state.CalloutcomeDetail = initialState.CalloutcomeDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearCallOutcomeDetails, setCallOutcomeID, clearCallOutcomeID } =
  calloutcomeSlice.actions;
export default calloutcomeSlice.reducer;
