import { configureStore } from "@reduxjs/toolkit";
import { getDefaultMiddleware } from "@reduxjs/toolkit";
import dialogSlice from "../components/dialog/dialogSlice";
import loginReducer from "../features/login/loginSlice";
import countrySlice from "../features/country/countrySlice";
import userSlice from "../features/user/userSlice";
import drawerSlice from "../components/drawer/drawerSlice";
import menuSlice from "../features/menu/menuSlice";
import rightsSlice from "../features/rights/rightsSlice";
import userTypeSlice from "../features/userType/userTypeSlice";
import citySlice from "../features/city/citySlice";
import stateSlice from "../features/state/stateSlice";
import typeofserviceSlice from "../features/typeofservice/typeofserviceSlice";
import businesstypeSlice from "../features/businesstype/businesstypeSlice";
import sectorSlice from "../features/sector/sectorSlice";
import portSlice from "../features/port/portSlice";
import departmentSlice from "../features/department/departmentSlice";
import branchSlice from "../features/branch/branchSlice";
import industriesSlice from "../features/Industries/industriesSlice";
import leadtypeSlice from "../features/leadtype/leadtypeSlice";
import leadsourceSlice from "../features/leadsource/leadsourceSlice";
import leadstatusSlice from "../features/leadstatus/leadstatusSlice";
import calltypeSlice from "../features/calltype/calltypeSlice";
import calloutcomeSlice from "../features/calloutcome/calloutcomeSlice";
import shippingmovementsSlice from "../features/shippingmovement/shippingmovemntsSlice";
import roleSlice from "../features/role/roleSlice";
import companytypeSlice from "../features/companytype/companytypeSlice";
import companycategorySlice from "../features/companycategory/companycategorySlice";

const customizedMiddleware = () =>
  getDefaultMiddleware({
    serializableCheck: false,
  });

const store = configureStore({
  reducer: {
    login: loginReducer,
    dialog: dialogSlice,
    drawer: drawerSlice,
    country: countrySlice,
    city: citySlice,
    state: stateSlice,
    user: userSlice,
    typeofservice: typeofserviceSlice,
    businesstype: businesstypeSlice,
    leadtype: leadtypeSlice,
    leadsource: leadsourceSlice,
    leadstatus: leadstatusSlice,
    sector: sectorSlice,
    port: portSlice,
    role:roleSlice,
    companytype:companytypeSlice,
    department: departmentSlice,
    branch: branchSlice,
    industries: industriesSlice,
    calltype: calltypeSlice,
    companycategory:companycategorySlice,
    calloutcome: calloutcomeSlice,
    shippingmovements: shippingmovementsSlice,
    menu: menuSlice,
    rights: rightsSlice,
    userType: userTypeSlice,
  },
  middleware: customizedMiddleware,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
