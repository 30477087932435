import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IBranch } from "../branchModel";

export const getAllBranch = createAsyncThunk(
  "Branch/FindAllBranch",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Branch/FindAllBranch`,
        {
          Branch: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      // console.error("Error Fetching Branch:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveBranch = createAsyncThunk(
  "Branch/FindAllActiveBranch",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Branch/FindAllActiveBranch`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Branch:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createBranch = createAsyncThunk(
  "Branch/InsertBranch",
  async (BranchData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Branch/InsertBranch`,
        BranchData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Branch:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateBranch = createAsyncThunk(
  "Branch/UpdatetBranch",
  async (BranchData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Branch/UpdatetBranch`,
        BranchData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Branch:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getBranchByID = createAsyncThunk(
  "Branch/FindByIDBranch",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Branch/FindByIDBranch`,
        { branch_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        branch_guid: result.branch_guid,
        branch: result.branch,
        isactive: result.isactive === true ? 1 : 2,
      } as IBranch;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Branch:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const branchActiveInactive = createAsyncThunk(
  "Branch/ActiveInActiveBranch",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Branch/ActiveInActiveBranch`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Branch:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteBranch = createAsyncThunk(
  "Branch/DeletetBranch",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Branch/DeletetBranch`,
        { branch_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Branch:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
