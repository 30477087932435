import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILeadSource, ILeadSourceState } from "./leadsourceModel";
import {
  createLeadSource,
  deleteLeadSource,
  getAllActiveLeadSource,
  getAllLeadSource,
  getLeadSourceByID,
  leadSourceActiveInactive,
  updateLeadSource,
} from "./services/leadsource.services";

const initialState: ILeadSourceState = {
  loading: false,
  formLoading: false,
  error: "",
  LeadSourceList: [],
  LeadSourceID: null,
  LeadSourceDetail: {
    lead_source: "",
    isactive: 1,
  },
};

const leadsourceSlice = createSlice({
  name: "leadsource",
  initialState,
  reducers: {
    clearLeadSourceDetails: (state) => {
      state.LeadSourceDetail = initialState.LeadSourceDetail;
    },
    setLeadSourceID: (state, action) => {
      state.LeadSourceID = action.payload;
    },
    clearLeadSourceID: (state) => {
      state.LeadSourceID = initialState.LeadSourceID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllLeadSource.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllLeadSource.fulfilled,
      (state, action: PayloadAction<ILeadSource[]>) => {
        state.loading = false;
        state.LeadSourceList = action.payload || [];
      }
    );
    builder.addCase(getAllLeadSource.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.LeadSourceList = [];
    });
    builder.addCase(getAllActiveLeadSource.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveLeadSource.fulfilled,
      (state, action: PayloadAction<ILeadSource[]>) => {
        state.loading = false;
        state.LeadSourceList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveLeadSource.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.LeadSourceList = [];
    });
    builder.addCase(createLeadSource.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createLeadSource.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createLeadSource.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateLeadSource.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateLeadSource.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateLeadSource.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteLeadSource.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteLeadSource.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteLeadSource.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(leadSourceActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(leadSourceActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(leadSourceActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getLeadSourceByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getLeadSourceByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.LeadSourceDetail = action.payload;
    });
    builder.addCase(getLeadSourceByID.rejected, (state, action) => {
      state.formLoading = false;
      state.LeadSourceDetail = initialState.LeadSourceDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearLeadSourceDetails, setLeadSourceID, clearLeadSourceID } =
  leadsourceSlice.actions;
export default leadsourceSlice.reducer;
