import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ICallType } from "../calltypeModel";

export const getAllCallType = createAsyncThunk(
  "CallType/FindAllCallType",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CallType/FindAllCallType`,
        {
          CallType: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching CallType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveCallType = createAsyncThunk(
  "CallType/FindAllActiveCallType",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/CallType/FindAllActiveCallType`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching CallType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createCallType = createAsyncThunk(
  "CallType/InsertCallType",
  async (CallTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CallType/InsertCallType`,
        CallTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating CallType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateCallType = createAsyncThunk(
  "CallType/UpdateCallType",
  async (CallTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CallType/UpdateCallType`,
        CallTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating CallType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCallTypeByID = createAsyncThunk(
  "CallType/FindByIDCallType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CallType/FindByIDCallType`,
        { call_type_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        call_type_guid: result.call_type_guid,
        call_type: result.call_type,

        isactive: result.isactive === true ? 1 : 2,
      } as ICallType;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching CallType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const callTypeActiveInactive = createAsyncThunk(
  "CallType/ActiveInActiveCallType",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CallType/ActiveInActiveCallType`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive CallType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteCallType = createAsyncThunk(
  "CallType/DeleteCallType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CallType/DeleteCallType`,
        { call_type_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting CallType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
