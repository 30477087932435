import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IIndustries, IIndustriesState } from "./industriesModel";
import {
  createIndustries,
  deleteIndustries,
  getAllActiveIndustries,
  getAllIndustries,
  getIndustriesByID,
  industriesActiveInactive,
  updateIndustries,
} from "./services/industries.services";

const initialState: IIndustriesState = {
  loading: false,
  formLoading: false,
  error: "",
  IndustriesList: [],
  IndustriesID: null,
  IndustriesDetail: {
    industries: "",
    isactive: 1,
  },
};

const industriesSlice = createSlice({
  name: "industries",
  initialState,
  reducers: {
    clearIndustriesDetails: (state) => {
      state.IndustriesDetail = initialState.IndustriesDetail;
    },
    setIndustriesID: (state, action) => {
      state.IndustriesID = action.payload;
    },
    clearIndustriesID: (state) => {
      state.IndustriesID = initialState.IndustriesID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllIndustries.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllIndustries.fulfilled,
      (state, action: PayloadAction<IIndustries[]>) => {
        state.loading = false;
        state.IndustriesList = action.payload || [];
      }
    );
    builder.addCase(getAllIndustries.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.IndustriesList = [];
    });
    builder.addCase(getAllActiveIndustries.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveIndustries.fulfilled,
      (state, action: PayloadAction<IIndustries[]>) => {
        state.loading = false;
        state.IndustriesList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveIndustries.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.IndustriesList = [];
    });
    builder.addCase(createIndustries.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createIndustries.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createIndustries.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateIndustries.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateIndustries.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateIndustries.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteIndustries.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteIndustries.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteIndustries.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(industriesActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(industriesActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(industriesActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getIndustriesByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getIndustriesByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.IndustriesDetail = action.payload;
    });
    builder.addCase(getIndustriesByID.rejected, (state, action) => {
      state.formLoading = false;
      state.IndustriesDetail = initialState.IndustriesDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearIndustriesDetails, setIndustriesID, clearIndustriesID } =
  industriesSlice.actions;
export default industriesSlice.reducer;
