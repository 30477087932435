import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ICompanyType } from "../companytypeModel";

export const getAllCompanyType = createAsyncThunk(
  "CompanyType/FindAllCompanyType",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CompanyType/FindAllCompanyType`,
        {
          name: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching CompanyType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveCompanyType = createAsyncThunk(
  "CompanyType/FindAllActiveCompanyType",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/CompanyType/FindAllActiveCompanyType`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching CompanyType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createCompanyType = createAsyncThunk(
  "CompanyType/InsertCompanyType",
  async (CompanyTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CompanyType/InsertCompanyType`,
        CompanyTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Company Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateCompanyType = createAsyncThunk(
  "CompanyType/UpdateCompanyType",
  async (CompanyTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CompanyType/UpdateCompanyType`,
        CompanyTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating CompanyType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCompanyTypeByID = createAsyncThunk(
  "CompanyType/FindByIDCompanyType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CompanyType/FindByIDCompanyType`,
        { company_type_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        company_type_guid: result.company_type_guid,
        name: result.name,

        isactive: result.isactive === true ? 1 : 2,
      } as ICompanyType;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Company Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const companyTypeActiveInactive = createAsyncThunk(
  "CompanyType/ActiveInActiveCompanyType",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CompanyType/ActiveInActiveCompanyType`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive CompanyType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteCompanyType = createAsyncThunk(
  "CompanyType/DeleteCompanyType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CompanyType/DeleteCompanyType`,
        { company_type_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting CompanyType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
