import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IShippingMovements,
  IShippingMovementsState,
} from "./shippingmomentModel";
import {
  createShippingMovements,
  deleteShippingMovements,
  getAllShippingMovements,
  getShippingMovementsByID,
  updateShippingMovements,
} from "./services/shippingmovement.services";

const initialState: IShippingMovementsState = {
  loading: false,
  formLoading: false,
  error: "",
  ShippingMovementsList: [],
  ShippingMovementsID: null,
  ShippingMovementsDetail: {
    shipping_movements: "",
  },
};

const shippingmovementsSlice = createSlice({
  name: "shippingmovements",
  initialState,
  reducers: {
    clearShippingMovementsDetails: (state) => {
      state.ShippingMovementsDetail = initialState.ShippingMovementsDetail;
    },
    setShippingMovementsID: (state, action) => {
      state.ShippingMovementsID = action.payload;
    },
    clearShippingMovementsID: (state) => {
      state.ShippingMovementsID = initialState.ShippingMovementsID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllShippingMovements.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllShippingMovements.fulfilled,
      (state, action: PayloadAction<IShippingMovements[]>) => {
        state.loading = false;
        state.ShippingMovementsList = action.payload || [];
      }
    );
    builder.addCase(getAllShippingMovements.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ShippingMovementsList = [];
    });

    builder.addCase(createShippingMovements.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createShippingMovements.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createShippingMovements.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateShippingMovements.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateShippingMovements.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateShippingMovements.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteShippingMovements.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteShippingMovements.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteShippingMovements.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getShippingMovementsByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(
      getShippingMovementsByID.fulfilled,
      (state, action: any) => {
        state.formLoading = false;
        state.ShippingMovementsDetail = action.payload;
      }
    );
    builder.addCase(getShippingMovementsByID.rejected, (state, action) => {
      state.formLoading = false;
      state.ShippingMovementsDetail = initialState.ShippingMovementsDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearShippingMovementsDetails,
  setShippingMovementsID,
  clearShippingMovementsID,
} = shippingmovementsSlice.actions;
export default shippingmovementsSlice.reducer;
