import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IRole } from "../roleModel";

export const getAllRole = createAsyncThunk("Role/FindAllRole", async () => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/Role/FindAllRole`,
      {
        name: "",
      }
    );
    return response.data?.Data;
  } catch (error: any) {
    // ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error Fetching Role:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const createRole = createAsyncThunk(
  "Role/InsertRole",
  async (RoleData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Role/InsertRole`,
        RoleData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Role:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateRole = createAsyncThunk(
  "Role/UpdateRole",
  async (RoleData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Role/UpdateRole`,
        RoleData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Role:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteRole = createAsyncThunk(
  "Role/DeleteRole",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Role/DeleteRole`,
        { role_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Role:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getRoleByID = createAsyncThunk(
  "Role/FindByIDRole",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Role/FindByIDRole`,
        { role_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        role_guid: result.role_guid,
        name: result.name,
        isactive: result.isactive === true ? 1 : 2,
      } as IRole;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Role:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveRole = createAsyncThunk(
  "Role/FindAllActiveRole",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Role/FindAllActiveRole`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Role:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const roleActiveInactive = createAsyncThunk(
  "Role/ActiveInActiveRole",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Role/ActiveInActiveRole`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive role:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
