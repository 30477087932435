import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { closed } from "../../components/drawer/drawerSlice";
import {
  createRights,
  getAllRights,
  getRightsByID,
  updateRights,
} from "./services/rights.services";
import { clearRightsDetails } from "./rightsSlice";
import { STATUSARRAY } from "../../_contstants/common";
import { RxCross1 } from "react-icons/rx";
import { Typography } from "@progress/kendo-react-common";

const CreateRights: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const RightsDetail = useAppSelector((state) => state.rights.RightsDetail);
  const rights_guid = useAppSelector((state) => state.drawer.data);
  const formLoading = useAppSelector((state) => state.rights.formLoading);

  useEffect(() => {
    if (rights_guid) {
      dispatch(getRightsByID(rights_guid));
    }
  }, [rights_guid]);

  useEffect(() => {
    return () => {
      dispatch(clearRightsDetails());
      dispatch(closed());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      if (!rights_guid) {
        const insertPayload = {
          name: values.name,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(createRights(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearRightsDetails());
          dispatch(getAllRights());
        }
      } else {
        const editPayload = {
          rights_guid: rights_guid,
          name: values.name,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(updateRights(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearRightsDetails());
          dispatch(getAllRights());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={RightsDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "17px 0 8px",
              }}
            >
              <Typography.h4 className="m-0">
                {rights_guid ? "Update Right" : "Create Right"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="name"
                label="Right Name"
                component={FormTextField}
                validator={requiredValidator}
                astrike={true}
                placeholder={"i.e. Add"}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="isactive"
                label="Status"
                component={FormSelectionField}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status?.value,
                    label: status?.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "end",
                marginTop: "20px",
                width: "100%",
              }}
            >

<ButtonWithLoading
                label={!rights_guid ? "Create" : "Update"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />

              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
           
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateRights;
