import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ICalloutCome } from "../calloutcomeModel";

export const getAllCallOutcome = createAsyncThunk(
  "CallOutcome/FindAllCallOutcome",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CallOutcome/FindAllCallOutcome`,
        {
          CallOutcome: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching CallOutcome:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveCallOutcome = createAsyncThunk(
  "CallOutcome/FindAllActiveCallOutcome",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/CallOutcome/FindAllActiveCallOutcome`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching CallOutcome:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createCallOutcome = createAsyncThunk(
  "CallOutcome/InsertCallOutcome",
  async (CallOutcomeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CallOutcome/InsertCallOutcome`,
        CallOutcomeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating CallOutcome:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateCallOutcome = createAsyncThunk(
  "CallOutcome/UpdateCallOutcome",
  async (CallOutcomeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CallOutcome/UpdateCallOutcome`,
        CallOutcomeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating CallOutcome:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCallOutcomeByID = createAsyncThunk(
  "CallOutcome/FindByIDCallOutcome",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CallOutcome/FindByIDCallOutcome`,
        { call_outcome_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        call_outcome_guid: result.call_outcome_guid,
        call_outcome: result.call_outcome,

        isactive: result.isactive === true ? 1 : 2,
      } as ICalloutCome;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching CallOutcome:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const callOutcomeActiveInactive = createAsyncThunk(
  "CallOutcome/ActiveInActiveCallOutcome",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CallOutcome/ActiveInActiveCallOutcome`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive CallOutcome:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteCallOutcome = createAsyncThunk(
  "CallOutcome/DeleteCallOutcome",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CallOutcome/DeleteCallOutcome`,
        { call_outcome_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting CallOutcome:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
