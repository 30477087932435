import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompanyCategory, ICompanyCategoryState } from "./companycategoryModel";
import { companycategoryActiveInactive, createCompanyCategory, deleteCompanyCategory, getAllActiveCompanyCategory, getAllCompanyCategory, getCompanyCategoryByID, updateCompanyCategory } from "./services/companycategory.services";


const initialState: ICompanyCategoryState = {
  loading: false,
  formLoading: false,
  error: "",
  CompanyCategoryList: [],
  CompanyCategoryID: null,
  CompanyCategoryDetail: {
    name: "",
    isactive: 1,
  },
};

const companycategorySlice = createSlice({
  name: "companycategory",
  initialState,
  reducers: {
    clearCompanyCategoryDetails: (state) => {
      state.CompanyCategoryDetail = initialState.CompanyCategoryDetail;
    },
    setCompanyCategoryID: (state, action) => {
      state.CompanyCategoryID = action.payload;
    },
    clearCompanyCategoryID: (state) => {
      state.CompanyCategoryID = initialState.CompanyCategoryID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCompanyCategory.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllCompanyCategory.fulfilled,
      (state, action: PayloadAction<ICompanyCategory[]>) => {
        state.loading = false;
        state.CompanyCategoryList = action.payload || [];
      }
    );
    builder.addCase(getAllCompanyCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CompanyCategoryList = [];
    });
    builder.addCase(getAllActiveCompanyCategory.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveCompanyCategory.fulfilled,
      (state, action: PayloadAction<ICompanyCategory[]>) => {
        state.loading = false;
        state.CompanyCategoryList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveCompanyCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CompanyCategoryList = [];
    });
    builder.addCase(createCompanyCategory.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createCompanyCategory.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createCompanyCategory.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateCompanyCategory.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateCompanyCategory.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateCompanyCategory.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteCompanyCategory.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteCompanyCategory.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCompanyCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(companycategoryActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(companycategoryActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(companycategoryActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getCompanyCategoryByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getCompanyCategoryByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.CompanyCategoryDetail = action.payload;
    });
    builder.addCase(getCompanyCategoryByID.rejected, (state, action) => {
      state.formLoading = false;
      state.CompanyCategoryDetail = initialState.CompanyCategoryDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearCompanyCategoryDetails, setCompanyCategoryID, clearCompanyCategoryID } =
  companycategorySlice.actions;
export default companycategorySlice.reducer;
