import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBranch, IBranchState } from "./branchModel";
import {
  branchActiveInactive,
  createBranch,
  deleteBranch,
  getAllActiveBranch,
  getAllBranch,
  getBranchByID,
  updateBranch,
} from "./services/branch.services";

const initialState: IBranchState = {
  loading: false,
  formLoading: false,
  error: "",
  BranchList: [],
  BranchID: null,
  BranchDetail: {
    branch: "",
    isactive: 1,
  },
};

const branchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    clearBranchDetails: (state) => {
      state.BranchDetail = initialState.BranchDetail;
    },
    setBranchID: (state, action) => {
      state.BranchID = action.payload;
    },
    clearBranchID: (state) => {
      state.BranchID = initialState.BranchID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllBranch.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllBranch.fulfilled,
      (state, action: PayloadAction<IBranch[]>) => {
        state.loading = false;
        state.BranchList = action.payload || [];
      }
    );
    builder.addCase(getAllBranch.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.BranchList = [];
    });
    builder.addCase(getAllActiveBranch.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveBranch.fulfilled,
      (state, action: PayloadAction<IBranch[]>) => {
        state.loading = false;
        state.BranchList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveBranch.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.BranchList = [];
    });
    builder.addCase(createBranch.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createBranch.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createBranch.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateBranch.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateBranch.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateBranch.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteBranch.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteBranch.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteBranch.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(branchActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(branchActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(branchActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getBranchByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getBranchByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.BranchDetail = action.payload;
    });
    builder.addCase(getBranchByID.rejected, (state, action) => {
      state.formLoading = false;
      state.BranchDetail = initialState.BranchDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearBranchDetails, setBranchID, clearBranchID } =
  branchSlice.actions;
export default branchSlice.reducer;
