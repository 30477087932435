import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILeadStatus, ILeadStatusState } from "./leadstatusModel";
import {
  createLeadStatus,
  deleteLeadStatus,
  getAllActiveLeadStatus,
  getAllLeadStatus,
  getLeadStatusByID,
  leadStatusActiveInactive,
  updateLeadStatus,
} from "./services/leadstatus.services";

const initialState: ILeadStatusState = {
  loading: false,
  formLoading: false,
  error: "",
  LeadStatusList: [],
  LeadStatusID: null,
  LeadStatusDetail: {
    lead_status: "",
    isactive: 1,
  },
};

const leadstatusSlice = createSlice({
  name: "leadstatus",
  initialState,
  reducers: {
    clearLeadStatusDetails: (state) => {
      state.LeadStatusDetail = initialState.LeadStatusDetail;
    },
    setLeadStatusID: (state, action) => {
      state.LeadStatusID = action.payload;
    },
    clearLeadStatusID: (state) => {
      state.LeadStatusID = initialState.LeadStatusID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllLeadStatus.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllLeadStatus.fulfilled,
      (state, action: PayloadAction<ILeadStatus[]>) => {
        state.loading = false;
        state.LeadStatusList = action.payload || [];
      }
    );
    builder.addCase(getAllLeadStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.LeadStatusList = [];
    });
    builder.addCase(getAllActiveLeadStatus.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveLeadStatus.fulfilled,
      (state, action: PayloadAction<ILeadStatus[]>) => {
        state.loading = false;
        state.LeadStatusList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveLeadStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.LeadStatusList = [];
    });
    builder.addCase(createLeadStatus.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createLeadStatus.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createLeadStatus.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateLeadStatus.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateLeadStatus.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateLeadStatus.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteLeadStatus.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteLeadStatus.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteLeadStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(leadStatusActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(leadStatusActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(leadStatusActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getLeadStatusByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getLeadStatusByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.LeadStatusDetail = action.payload;
    });
    builder.addCase(getLeadStatusByID.rejected, (state, action) => {
      state.formLoading = false;
      state.LeadStatusDetail = initialState.LeadStatusDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearLeadStatusDetails, setLeadStatusID, clearLeadStatusID } =
  leadstatusSlice.actions;
export default leadstatusSlice.reducer;
