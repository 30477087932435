import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Anchor = "top" | "left" | "bottom" | "right";

export interface IDrawerState {
  open: boolean;
  anchor?: Anchor;
  drawerWidth?: number;
  name: string;
  data?: any;
  label?: string;
  
  
}

const initialState: IDrawerState = {
  open: false,
  anchor: "left",
  drawerWidth: 250,
  name: "muidrawer",
  data: null,
  label: "",
};

const muiDrawerSlice = createSlice({
  name: "muidrawer",
  initialState,
  reducers: {
    opened: (state, action: PayloadAction<IDrawerState>) => {
      state.open = action.payload.open;
      state.anchor = action.payload.anchor;
      state.drawerWidth = action.payload.drawerWidth
        ? action.payload.drawerWidth
        : state.drawerWidth;
      state.name = action.payload.name;
      state.data = action.payload.data ? action.payload.data : null;
      state.label = action.payload.label ? action.payload.label : "";
    },
    closed: (state) => {
      state.open = false;
      state.name = "muidrawer";
      state.data = null;
    },
  },
});

export default muiDrawerSlice.reducer;
export const { opened, closed } = muiDrawerSlice.actions;
