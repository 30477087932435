import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompanyType, ICompanyTypeState } from "./companytypeModel";
import {
  companyTypeActiveInactive,
  createCompanyType,
  deleteCompanyType,
  getAllActiveCompanyType,
  getAllCompanyType,
  getCompanyTypeByID,
  updateCompanyType,
} from "./services/companytype.services";

const initialState: ICompanyTypeState = {
  loading: false,
  formLoading: false,
  error: "",
  CompanyTypeList: [],
  CompanyTypeID: null,
  CompanyTypeDetail: {
    name: "",
    isactive: 1,
  },
};

const companytypeSlice = createSlice({
  name: "name",
  initialState,
  reducers: {
    clearCompanyTypeDetails: (state) => {
      state.CompanyTypeDetail = initialState.CompanyTypeDetail;
    },
    setCompanyTypeID: (state, action) => {
      state.CompanyTypeID = action.payload;
    },
    clearCompanyTypeID: (state) => {
      state.CompanyTypeID = initialState.CompanyTypeID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCompanyType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllCompanyType.fulfilled,
      (state, action: PayloadAction<ICompanyType[]>) => {
        state.loading = false;
        state.CompanyTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllCompanyType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CompanyTypeList = [];
    });
    builder.addCase(getAllActiveCompanyType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveCompanyType.fulfilled,
      (state, action: PayloadAction<ICompanyType[]>) => {
        state.loading = false;
        state.CompanyTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveCompanyType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CompanyTypeList = [];
    });
    builder.addCase(createCompanyType.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createCompanyType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createCompanyType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateCompanyType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateCompanyType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateCompanyType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteCompanyType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteCompanyType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCompanyType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(companyTypeActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(companyTypeActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(companyTypeActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getCompanyTypeByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getCompanyTypeByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.CompanyTypeDetail = action.payload;
    });
    builder.addCase(getCompanyTypeByID.rejected, (state, action) => {
      state.formLoading = false;
      state.CompanyTypeDetail = initialState.CompanyTypeDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearCompanyTypeDetails, setCompanyTypeID, clearCompanyTypeID } =
  companytypeSlice.actions;
export default companytypeSlice.reducer;
