import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IShippingMovements } from "../shippingmomentModel";

export const getAllShippingMovements = createAsyncThunk(
  "ShippingMovements/FindAllShippingMovements",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ShippingMovements/FindAllShippingMovements`,
        {
          ShippingMovements: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching ShippingMovements:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createShippingMovements = createAsyncThunk(
  "ShippingMovements/InsertShippingMovements",
  async (ShippingMovementsData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ShippingMovements/InsertShippingMovements`,
        ShippingMovementsData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating ShippingMovements:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateShippingMovements = createAsyncThunk(
  "ShippingMovements/UpdateShippingMovements",
  async (ShippingMovementsData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ShippingMovements/UpdateShippingMovements`,
        ShippingMovementsData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating ShippingMovements:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getShippingMovementsByID = createAsyncThunk(
  "ShippingMovements/FindByIDShippingMovements",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ShippingMovements/FindByIDShippingMovements`,
        { shipping_movements_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        shipping_movements_guid: result.shipping_movements_guid,
        shipping_movements: result.shipping_movements,

        isactive: result.isactive === true ? 1 : 2,
      } as IShippingMovements;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching ShippingMovements:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteShippingMovements = createAsyncThunk(
  "ShippingMovements/DeleteShippingMovements",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ShippingMovements/DeleteShippingMovements`,
        { shipping_movements_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting ShippingMovements:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
