import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITypeofService, ITypeofServiceState } from "./typeofserviceModel";
import {
  createTypeofService,
  deleteTypeOfService,
  getAllActiveTypeOfService,
  getAllTypeofService,
  getTypeOfServiceByID,
  TypeOfServiceActiveInactive,
  updateTypeOfService,
} from "./services/typeofservice.services";

const initialState: ITypeofServiceState = {
  loading: false,
  formLoading: false,
  error: "",
  TypeofServiceList: [],
  TypeofServiceID: null,
  TypeofServiceDetail: {
    types_of_services: "",
    isactive: 1,
  },
};

const typesofservicesSlice = createSlice({
  name: "typeofservice",
  initialState,
  reducers: {
    clearTypeofServiceDetails: (state) => {
      state.TypeofServiceDetail = initialState.TypeofServiceDetail;
    },
    setTypeofServiceID: (state, action) => {
      state.TypeofServiceID = action.payload;
    },
    clearTypeofServiceID: (state) => {
      state.TypeofServiceID = initialState.TypeofServiceID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTypeofService.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllTypeofService.fulfilled,
      (state, action: PayloadAction<ITypeofService[]>) => {
        state.loading = false;
        state.TypeofServiceList = action.payload || [];
      }
    );
    builder.addCase(getAllTypeofService.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TypeofServiceList = [];
    });
    builder.addCase(getAllActiveTypeOfService.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveTypeOfService.fulfilled,
      (state, action: PayloadAction<ITypeofService[]>) => {
        state.loading = false;
        state.TypeofServiceList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveTypeOfService.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TypeofServiceList = [];
    });
    builder.addCase(createTypeofService.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createTypeofService.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createTypeofService.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateTypeOfService.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateTypeOfService.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateTypeOfService.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteTypeOfService.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteTypeOfService.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTypeOfService.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(TypeOfServiceActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(TypeOfServiceActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(TypeOfServiceActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTypeOfServiceByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getTypeOfServiceByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.TypeofServiceDetail = action.payload;
    });
    builder.addCase(getTypeOfServiceByID.rejected, (state, action) => {
      state.formLoading = false;
      state.TypeofServiceDetail = initialState.TypeofServiceDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearTypeofServiceDetails,
  setTypeofServiceID,
  clearTypeofServiceID,
} = typesofservicesSlice.actions;
export default typesofservicesSlice.reducer;
