import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICallType, ICallTypeState } from "./calltypeModel";
import {
  callTypeActiveInactive,
  createCallType,
  deleteCallType,
  getAllActiveCallType,
  getAllCallType,
  getCallTypeByID,
  updateCallType,
} from "./services/calltype.services";

const initialState: ICallTypeState = {
  loading: false,
  formLoading: false,
  error: "",
  CallTypeList: [],
  CallTypeID: null,
  CallTypeDetail: {
    call_type: "",
    isactive: 1,
  },
};

const calltypeSlice = createSlice({
  name: "calltype",
  initialState,
  reducers: {
    clearCallTypeDetails: (state) => {
      state.CallTypeDetail = initialState.CallTypeDetail;
    },
    setCallTypeID: (state, action) => {
      state.CallTypeID = action.payload;
    },
    clearCallTypeID: (state) => {
      state.CallTypeID = initialState.CallTypeID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCallType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllCallType.fulfilled,
      (state, action: PayloadAction<ICallType[]>) => {
        state.loading = false;
        state.CallTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllCallType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CallTypeList = [];
    });
    builder.addCase(getAllActiveCallType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveCallType.fulfilled,
      (state, action: PayloadAction<ICallType[]>) => {
        state.loading = false;
        state.CallTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveCallType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CallTypeList = [];
    });
    builder.addCase(createCallType.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createCallType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createCallType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateCallType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateCallType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateCallType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteCallType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteCallType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCallType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(callTypeActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(callTypeActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(callTypeActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getCallTypeByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getCallTypeByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.CallTypeDetail = action.payload;
    });
    builder.addCase(getCallTypeByID.rejected, (state, action) => {
      state.formLoading = false;
      state.CallTypeDetail = initialState.CallTypeDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearCallTypeDetails, setCallTypeID, clearCallTypeID } =
  calltypeSlice.actions;
export default calltypeSlice.reducer;
