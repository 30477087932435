import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IDepartment } from "../departmentModel";

export const getAllDepartment = createAsyncThunk(
  "Department/FindAllDepartment",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Department/FindAllDepartment`,
        {
          department: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Department:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createDepartment = createAsyncThunk(
  "Department/InsertDepartment",
  async (department: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Department/InsertDepartment`,
        department
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast("Department already exist!");
      console.error("Error Fetching Department:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateDepartment = createAsyncThunk(
  "Department/UpdateDepartment",
  async (department: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Department/UpdateDepartment`,
        department
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Department:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getItemDepartmentById = createAsyncThunk(
  "Department/FindByIDDepartment",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Department/FindByIDDepartment`,
        {
          department_guid: ID,
        }
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data as any;
      return {
        department_guid: result.department_guid,
        department: result.department,
        isactive: result.isactive === true ? 1 : 2,
      } as IDepartment;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Department:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteDepartment = createAsyncThunk(
  "Department/DeleteDepartment",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Department/DeleteDepartment`,
        {
          department_guid: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Department:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveDepartment = createAsyncThunk(
  "Department/FindAllActiveDepartment",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Department/FindAllActiveDepartment`
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Department:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const DepartmentActiveInactive = createAsyncThunk(
  "Department/ActiveInActiveDepartment",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Department/ActiveInActiveDepartment`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Department:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
