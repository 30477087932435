import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";

import { closed } from "../../components/drawer/drawerSlice";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { STATUSARRAY } from "../../_contstants/common";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";
import { clearTypeofServiceDetails } from "./typeofserviceSlice";
import {
  createTypeofService,
  getAllTypeofService,
  getTypeOfServiceByID,
  updateTypeOfService,
} from "./services/typeofservice.services";

const CreateTypeofService: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const TypeofServiceDetail = useAppSelector(
    (state) => state.typeofservice.TypeofServiceDetail
  );
  const types_of_services_guid = useAppSelector((state) => state.drawer.data);
  const formLoading = useAppSelector(
    (state) => state.typeofservice.formLoading
  );

  useEffect(() => {
    return () => {
      dispatch(clearTypeofServiceDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (types_of_services_guid) {
      dispatch(getTypeOfServiceByID(types_of_services_guid));
    }
  }, [types_of_services_guid]);

  const handleSubmit = async (values: any) => {
    try {
      if (!types_of_services_guid) {
        const insertPayload = {
          types_of_services: values.types_of_services,

          isactive: values.isactive === 1,
        };
        const response = await dispatch(createTypeofService(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearTypeofServiceDetails());
          dispatch(getAllTypeofService());
        }
      } else {
        const editPayload = {
          types_of_services_guid: types_of_services_guid,
          types_of_services: values.types_of_services,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(updateTypeOfService(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearTypeofServiceDetails());
          dispatch(getAllTypeofService());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={TypeofServiceDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "17px 0 8px",
              }}
            >
              <Typography.h4 className="m-0">
                {types_of_services_guid
                  ? "Update Type of Service"
                  : "Create Type of Service"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="types_of_services"
                label="Type of Service"
                placeholder="Type of Service"
                component={FormTextField}
                validator={requiredValidator}
                astrike={true}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"IsActive"}
                name={"isactive"}
                component={FormSelectionField}
                label={"Status"}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status.value,
                    label: status.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "end",
                marginTop: "10px",
                width: "100%",
              }}
            >

<ButtonWithLoading
                label={types_of_services_guid ? "Update" : "Create"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />

              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
              
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateTypeofService;
