import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IPort } from "../portModel";

export const getAllPort = createAsyncThunk("Port/FindAllPort", async () => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/Port/FindAllPort`,
      {
        PortName: "",
      }
    );
    return response.data?.Data;
  } catch (error: any) {
    // ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error Fetching port:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const createPort = createAsyncThunk(
  "Port/InsertPort",
  async (PortData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Port/InsertPort`,
        PortData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating port:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updatePort = createAsyncThunk(
  "Port/UpdatePort",
  async (PortData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Port/UpdatePort`,
        PortData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Port:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deletePort = createAsyncThunk(
  "Port/DeletePort",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Port/DeletePort`,
        { port_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting port:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPortByID = createAsyncThunk(
  "Port/FindByIDPort",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Port/FindByIDPort`,
        { port_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        port_guid: result.port_guid,
        sector_id: result.sector_id,
        port_name: result.port_name,
        isactive: result.isactive ? 1 : 2,
      } as IPort;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching port:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActivePort = createAsyncThunk(
  "Port/FindAllActivePort",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Port/FindAllActivePort`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching port:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const portActiveInactive = createAsyncThunk(
  "Port/ActiveInActivePort",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Port/ActiveInActivePort`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive port:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
