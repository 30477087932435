import * as React from "react";
import {
  ComboBox,
  ComboBoxFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { TextBoxProps } from "@progress/kendo-react-inputs";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { FilterDescriptor, filterBy } from "@progress/kendo-data-query";
import { Error, Hint, Label } from "@progress/kendo-react-labels";
import { CgDanger } from "react-icons/cg";
import { useAppDispatch } from "../../app/hooks";
import { DRAWER_WIDTH } from "../../_contstants/common";
import { IDrawerState, opened } from "../drawer/drawerSlice";
const delay = 300;

const FormSelectionField = (
  fieldRenderProps: FieldRenderProps & TextBoxProps
) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    value,
    onChange,
    options,
    isAddNew,
    drawerName,
    addNewLink,
    textField = "label",
    wrapperStyle,
    size = "large",
    wrapperClassName,
    astrike = false,
    name,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef<any>(null);
  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";
  const dispatch = useAppDispatch();

  const [data, setData] = React.useState(options);
  const [loading, setLoading] = React.useState(false);
  // eslint-disable-next-line no-undef
  const timeout = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    let updatedData = options;
    if (isAddNew) {
      updatedData = [...updatedData, { label: "Add New", value: "addnew" }];
    }
    setData(updatedData);
  }, [options, isAddNew]);

  const filterData = (filter: FilterDescriptor) => {
    let updatedData = options;
    if (isAddNew) {
      updatedData = [...updatedData, { label: "Add New", value: "addnew" }];
    }
    const data = updatedData?.slice();
    return filterBy(data, filter);
  };

  const filterChange = (event: ComboBoxFilterChangeEvent) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      setData(filterData(event.filter));
      setLoading(false);
    }, delay);

    setLoading(true);
  };

  const handleSelect = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue?.value === "addnew") {
      if (addNewLink) {
        window.open(`${location.origin}/${addNewLink}`);
      } else {
        const drawerOptions: IDrawerState = {
          open: true,
          anchor: "right",
          name: drawerName,
          drawerWidth: DRAWER_WIDTH,
        };
        dispatch(opened(drawerOptions));
      }
    } else {
      onChange(selectedValue);
    }
  };

  let initialValue = options?.find((option: any) => option.value === value);

  return (
    <>
      <FieldWrapper
        style={wrapperStyle}
        className={`position-relative ${wrapperClassName}`}
      >
        <Label
          // editorValue={value}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          // label={label}
          //   labelClassName="k-form-label"
        >
          {label} {astrike && <span style={{ color: "red" }}>*</span>}
        </Label>
        <ComboBox
          ariaLabelledBy={labelId}
          size={size}
          ariaDescribedBy={`${hintId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
          data={data}
          name={name}
          value={initialValue || null}
          filterable={true}
          onFilterChange={filterChange}
          loading={loading}
          textField={textField}
          defaultValue={value}
          onChange={handleSelect}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error className="d-flex justify-content-end" id={errorId}>
            <span
              className="d-flex align-items-center"
              style={{
                background: "#DF4957",
                color: "white",
                padding: "1px 8px 1px 5px",
                borderRadius: 10,
                gap: 5,
              }}
            >
              <CgDanger /> {validationMessage}
            </span>
          </Error>
        )}
      </FieldWrapper>
    </>
  );
};

export default FormSelectionField;
