import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ITypeofService } from "../typeofserviceModel";


export const getAllTypeofService = createAsyncThunk(
  "TypesOfServices/FindAllTypesOfServices",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TypesOfServices/FindAllTypesOfServices`,
        {
            TypeofService: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Service:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveTypeOfService = createAsyncThunk(
  "TypesOfServices/FindAllActiveTypesOfServices",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/TypesOfServices/FindAllActiveTypesOfServices`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching TypesOfServices:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createTypeofService = createAsyncThunk(
  "TypesOfServices/InsertTypesOfServices",
  async (TypesOfServiceData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TypesOfServices/InsertTypesOfServices`,
        TypesOfServiceData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating TypesOfService:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateTypeOfService = createAsyncThunk(
  "TypesOfServices/UpdateTypesOfServices",
  async (TypesOfServiceData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TypesOfServices/UpdateTypesOfServices`,
        TypesOfServiceData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating TypesOfService:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTypeOfServiceByID = createAsyncThunk(
  "TypesOfServices/FindByIDTypesOfServices",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TypesOfServices/FindByIDTypesOfServices`,
        {types_of_services_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        types_of_services_guid: result.types_of_services_guid,
        types_of_services: result.types_of_services,
        
        isactive: result.isactive === true ? 1 : 2,
      } as ITypeofService;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching TypeOfService:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const TypeOfServiceActiveInactive = createAsyncThunk(
  "TypesOfServices/ActiveInActiveTypesOfServices",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TypesOfServices/ActiveInActiveTypesOfServices`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive TypeOfService:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteTypeOfService = createAsyncThunk(
  "TypesOfServices/DeleteTypesOfServices",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TypesOfServices/DeleteTypesOfServices`,
        {types_of_services_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting typeofservice:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
