import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IIndustries } from "../industriesModel";

export const getAllIndustries = createAsyncThunk(
  "Industries/FindAllIndustries",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Industries/FindAllIndustries`,
        {
          Industries: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Industries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveIndustries = createAsyncThunk(
  "Industries/FindAllActiveIndustries",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Industries/FindAllActiveIndustries`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Industries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createIndustries = createAsyncThunk(
  "Industries/InsertIndustries",
  async (IndustriesData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Industries/InsertIndustries`,
        IndustriesData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Industries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateIndustries = createAsyncThunk(
  "Industries/UpdateIndustries",
  async (IndustriesData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Industries/UpdateIndustries`,
        IndustriesData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Industries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getIndustriesByID = createAsyncThunk(
  "Industries/FindByIDIndustries",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Industries/FindByIDIndustries`,
        { industries_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        industries_guid: result.industries_guid,
        industries: result.industries,

        isactive: result.isactive === true ? 1 : 2,
      } as IIndustries;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Industries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const industriesActiveInactive = createAsyncThunk(
  "Industries/ActiveInActiveIndustries",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Industries/ActiveInActiveIndustries`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Industries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteIndustries = createAsyncThunk(
  "Industries/DeleteIndustries",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Industries/DeleteIndustries`,
        { industries_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Industries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
