import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../app/config";
import { ILogin } from "./loginModel";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";

const initialState = {
  loading: false,
  error: "",
  data: null,
};

export const employeeLogin = createAsyncThunk(
  "login/UserLogin",
  async (data: ILogin) => {
    try {
      const response = await axios.post(`${API_URL}/UserLogin/UserLogin`, data);
      SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error login:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(employeeLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      employeeLogin.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.data = action.payload;
        state.loading = false;
        state.error = "";
        const result = action.payload;
        if (result?.token) {
          localStorage.setItem("Token", result?.token);
          localStorage.setItem("RefreshToken", result?.refreshtoken);
          localStorage.setItem("UserID", result?.id);
          localStorage.setItem("Name", result?.name);
          localStorage.setItem("UserName", result?.user_name);
          localStorage.setItem("EmailID", result?.emailid);
          localStorage.setItem("IsUser", result?.isuser);
          localStorage.setItem(
            "UserRightsAssign",
            JSON.stringify(result?.UserRightsAssign)
          );
        }
      }
    );
    builder.addCase(employeeLogin.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default loginSlice.reducer;
