import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDepartment, IDepartmentState } from "./departmentModel";
import {
  createDepartment,
  deleteDepartment,
  DepartmentActiveInactive,
  findAllActiveDepartment,
  getAllDepartment,
  getItemDepartmentById,
  updateDepartment,
} from "./services/department.services";

const initialState: IDepartmentState = {
  loading: false,
  formLoading: false,
  error: "",
  DepartmentList: [],
  DepartmentID: null,
  DepartmentDetail: {
    department: "",
    isactive: 1,
  },
};

export const departmentSlice = createSlice({
  name: "department",
  initialState,
  reducers: {
    clearDepartmentDetails: (state) => {
      state.DepartmentDetail = initialState.DepartmentDetail;
    },
    setDepartmentID: (state, action) => {
      state.DepartmentID = action.payload;
    },
    clearDepartmentID: (state) => {
      state.DepartmentID = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDepartment.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllDepartment.fulfilled,
      (state, action: PayloadAction<IDepartment[]>) => {
        state.loading = false;
        state.DepartmentList = action.payload;
      }
    );
    builder.addCase(getAllDepartment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.DepartmentList = [];
    });

    builder.addCase(findAllActiveDepartment.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveDepartment.fulfilled,
      (state, action: PayloadAction<IDepartment[]>) => {
        state.loading = false;
        state.DepartmentList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveDepartment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.DepartmentList = [];
    });

    builder.addCase(createDepartment.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createDepartment.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createDepartment.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateDepartment.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateDepartment.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateDepartment.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteDepartment.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteDepartment.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteDepartment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(DepartmentActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(DepartmentActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(DepartmentActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getItemDepartmentById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getItemDepartmentById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.DepartmentDetail = action.payload;
    });
    builder.addCase(getItemDepartmentById.rejected, (state, action) => {
      state.formLoading = false;
      state.DepartmentDetail = initialState.DepartmentDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearDepartmentDetails, setDepartmentID, clearDepartmentID } =
  departmentSlice.actions;
export default departmentSlice.reducer;
