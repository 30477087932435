import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { closed } from "../../components/drawer/drawerSlice";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";
import { STATUSARRAY } from "../../_contstants/common";
import { getAllActiveSector } from "../sector/services/sector.services";
import { clearPortDetails } from "./portSlice";
import {
  createPort,
  getAllPort,
  getPortByID,
  updatePort,
} from "./services/port.services";

const CreatePort: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const SectorList = useAppSelector((state) => state.sector.SectorList);
  const PortDetail = useAppSelector((state) => state.port.PortDetail);
  const port_guid = useAppSelector((state) => state.drawer.data);
  const formLoading = useAppSelector((state) => state.port.formLoading);

  useEffect(() => {
    dispatch(getAllActiveSector());
    return () => {
      dispatch(clearPortDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (port_guid) {
      dispatch(getPortByID(port_guid));
    }
  }, [port_guid]);

  const handleSubmit = async (values: any) => {
    try {
      if (!port_guid) {
        const insertPayload = {
          port_name: values.port_name,
          sector_id: values.sector_id,
          isactive: values?.isactive === 1,
        };
        const response = await dispatch(createPort(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearPortDetails());
          dispatch(getAllPort());
        }
      } else {
        const editPayload = {
          port_guid: values.port_guid,
          port_name: values.port_name,
          sector_id: values.sector_id,
          isactive: values?.isactive === 1,
        };
        const response = await dispatch(updatePort(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearPortDetails());
          dispatch(getAllPort());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={PortDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "17px 0 8px",
              }}
            >
              <Typography.h4 className="m-0">
                {port_guid ? "Update Port" : "Create Port"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="port_name"
                label="Port Name"
                placeholder="Port Name"
                astrike={true}
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name={"sector_id"}
                label={"Sector Name"}
                placeholder="Sector Name"
                component={FormSelectionField}
                validator={requiredValidator}
                astrike={true}
                options={SectorList.map((sector: any) => {
                  return {
                    value: sector.id,
                    label: sector.sector,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"IsActive"}
                name={"isactive"}
                label={"Status"}
                component={FormSelectionField}
                validator={requiredValidator}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status?.value,
                    label: status?.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "end",
                marginTop: "10px",
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={port_guid ? "Update" : "Create"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />

              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreatePort;
