import React from "react";
import { PieChartOutlined, UserOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Dashboard", "", <PieChartOutlined />),
  getItem("User Management", "usermanagement", <UserOutlined />, [
    getItem("User", "user"),
    getItem("User Type", "usertype"),
    getItem("Menu", "menu"),
    getItem("Rights", "rights"),
  ]),
  getItem("Masters", "masters", <UserOutlined />, [
    getItem("Country", "country"),
    getItem("State", "state"),
    getItem("City", "city"),
    getItem("Sector", "sector"),
    getItem("Type of Service", "typeofservice"),
    getItem("Business Type", "businesstype"),
    getItem("Port", "port"),
    getItem("Department", "department"),
    getItem("Branch", "branch"),
    getItem("Industries", "industries"),
    getItem("Lead Type", "leadtype"),
    getItem("Lead Source", "leadsource"),
    getItem("Lead Status", "leadstatus"),
    getItem("Call Type", "calltype"),
    getItem("Call Outcome", "calloutcome"),
    getItem("Company Type", "companytype"),
    getItem("Role", "role"),
    getItem("Company Category", "companycategory"),
  ]),
];

interface HeaderProps {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClick: MenuProps["onClick"] = (e) => {
    navigate(e.key);
  };

  const userResponseString = localStorage.getItem("UserRightsAssign") || "[]";
  let userResponse: {
    menu_id: number;
    menu_key: string;
    menu_name: string;
    rights_id: string;
  }[];

  try {
    userResponse = JSON.parse(userResponseString);
  } catch (error) {
    userResponse = [];
  }

  const allowedKeys = new Set(userResponse.map((item) => item.menu_key));

  function filterMenuItems(menuItems: MenuItem[]): MenuItem[] {
    return menuItems.reduce((filteredItems, item: any) => {
      if (allowedKeys.has(item.key)) {
        if (item.children) {
          const filteredChildren = filterMenuItems(item.children);
          filteredItems.push({ ...item, children: filteredChildren });
        } else {
          filteredItems.push(item);
        }
      } else if (item.children) {
        const filteredChildren = filterMenuItems(item.children);
        if (filteredChildren.length > 0) {
          filteredItems.push({ ...item, children: filteredChildren });
        }
      }
      return filteredItems;
    }, [] as MenuItem[]);
  }

  let filteredItems = filterMenuItems(items);

  const hasDashboardAccess = userResponse.some(
    (item) => item.menu_key === "dashboard"
  );
  if (hasDashboardAccess) {
    filteredItems = [
      getItem("Dashboard", "", <PieChartOutlined />),
      ...filteredItems,
    ];
  }

  function mapToGetItemFormat(menuItems: MenuItem[]): MenuItem[] {
    return menuItems.map((item: any) =>
      getItem(
        item.label,
        item.key,
        item.icon,
        item.children ? mapToGetItemFormat(item.children) : undefined
      )
    );
  }

  const formattedItems = mapToGetItemFormat(filteredItems);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className="drawer-logo">
        <h3 style={{ textAlign: "center", color: "white" }}>GS</h3>
      </div>
      <Menu
        className="sider-scroll"
        style={{
          overflow: "auto",
          height: "calc(100vh - 115px)",
        }}
        onClick={onClick}
        theme="dark"
        defaultSelectedKeys={[""]}
        selectedKeys={[location.pathname?.substring(1)?.split("/")[0]]}
        mode="inline"
        items={formattedItems}
        // items={items}
      />
    </Sider>
  );
};

export default Header;
