import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ICompanyCategory } from "../companycategoryModel";

export const getAllCompanyCategory = createAsyncThunk(
  "CompanyCategory/FindAllCompanyCategory",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CompanyCategory/FindAllCompanyCategory`,
        {
          name: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Company Category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveCompanyCategory = createAsyncThunk(
  "CompanyCategory/FindAllActiveCompanyCategory",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/CompanyCategory/FindAllActiveCompanyCategory`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Company Category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createCompanyCategory = createAsyncThunk(
  "CompanyCategory/InsertCompanyCategory",
  async (CompanyCategoryData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CompanyCategory/InsertCompanyCategory`,
        CompanyCategoryData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Company Category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateCompanyCategory = createAsyncThunk(
  "CompanyCategory/UpdateCompanyCategory",
  async (CompanyCategoryData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CompanyCategory/UpdateCompanyCategory `,
        CompanyCategoryData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Company Category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCompanyCategoryByID = createAsyncThunk(
  "CompanyCategory/FindByIDCompanyCategory",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CompanyCategory/FindByIDCompanyCategory`,
        { company_category_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        company_category_guid: result.company_category_guid,
        name: result.name,
        isactive: result.isactive === true ? 1 : 2,
      } as ICompanyCategory;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Company Category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const companycategoryActiveInactive = createAsyncThunk(
  "CompanyCategory/ActiveInActiveCompanyCategory",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CompanyCategory/ActiveInActiveCompanyCategory`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Company Category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteCompanyCategory = createAsyncThunk(
  "CompanyCategory/DeleteCompanyCategory",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/CompanyCategory/DeleteCompanyCategory`,
        { company_category_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Company Category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
