import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBusinessType, IBusinessTypeState } from "./businesstypeModel";
import {
  businessTypeActiveInactive,
  createBusinessType,
  deleteBusinessType,
  getAllActiveBusinessType,
  getAllBusinessType,
  getBusinessTypeByID,
  updateBusinessType,
} from "./services/businesstype.services";

const initialState: IBusinessTypeState = {
  loading: false,
  formLoading: false,
  error: "",
  BusinessTypeList: [],
  BusinessTypeID: null,
  BusinessTypeDetail: {
    business_type: "",
    isactive: 1,
  },
};

const businesstypeSlice = createSlice({
  name: "businesstype",
  initialState,
  reducers: {
    clearBusinessTypeDetails: (state) => {
      state.BusinessTypeDetail = initialState.BusinessTypeDetail;
    },
    setBusinessTypeID: (state, action) => {
      state.BusinessTypeID = action.payload;
    },
    clearBusinessTypeID: (state) => {
      state.BusinessTypeID = initialState.BusinessTypeID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllBusinessType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllBusinessType.fulfilled,
      (state, action: PayloadAction<IBusinessType[]>) => {
        state.loading = false;
        state.BusinessTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllBusinessType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.BusinessTypeList = [];
    });
    builder.addCase(getAllActiveBusinessType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveBusinessType.fulfilled,
      (state, action: PayloadAction<IBusinessType[]>) => {
        state.loading = false;
        state.BusinessTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveBusinessType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.BusinessTypeList = [];
    });
    builder.addCase(createBusinessType.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createBusinessType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createBusinessType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateBusinessType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateBusinessType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateBusinessType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteBusinessType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteBusinessType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteBusinessType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(businessTypeActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(businessTypeActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(businessTypeActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getBusinessTypeByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getBusinessTypeByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.BusinessTypeDetail = action.payload;
    });
    builder.addCase(getBusinessTypeByID.rejected, (state, action) => {
      state.formLoading = false;
      state.BusinessTypeDetail = initialState.BusinessTypeDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearBusinessTypeDetails,
  setBusinessTypeID,
  clearBusinessTypeID,
} = businesstypeSlice.actions;
export default businesstypeSlice.reducer;
