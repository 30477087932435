import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  createMenu,
  getAllActiveMenuSubMenuList,
  getAllMenus,
  getMenuByID,
  updateMenu,
} from "./services/menu.services";
import { closed } from "../../components/drawer/drawerSlice";
import { clearMenuDetails } from "./menuSlice";
import FormCheckbox from "../../components/formFields/FormCheckbox";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { IMenuSubMenuList } from "./menuModel";
import { STATUSARRAY } from "../../_contstants/common";
import { RxCross1 } from "react-icons/rx";
import { Typography } from "@progress/kendo-react-common";

// interface FormChangeWatcherProps {
//   formRenderProps: FormRenderProps;
// }

// const PaxCountChangeWatcher: React.FC<FormChangeWatcherProps> = ({
//   formRenderProps,
// }) => {
//   const Adult = formRenderProps.valueGetter("Adult");
//   const ExtraAdult = formRenderProps.valueGetter("ExtraAdult");
//   const CWB = formRenderProps.valueGetter("CWB");
//   const CNB = formRenderProps.valueGetter("CNB");
//   const Infant = formRenderProps.valueGetter("Infant");
//   useEffect(() => {
//     formRenderProps.onChange("PaxCount", {
//       value: +Adult + +ExtraAdult + +CWB + +CNB + +Infant,
//     });
//   }, [Adult, ExtraAdult, CWB, CNB, Infant]);
//   return null;
// };

const CreateMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const MenuDetail = useAppSelector((state) => state.menu.MenuDetail);
  const MenuSubMenuList = useAppSelector((state) => state.menu.MenuSubMenuList);
  const menu_guid = useAppSelector((state) => state.drawer.data);
  const formLoading = useAppSelector((state) => state.menu.formLoading);

  useEffect(() => {
    if (menu_guid) {
      dispatch(getMenuByID(menu_guid));
    }
  }, [menu_guid]);

  useEffect(() => {
    dispatch(getAllActiveMenuSubMenuList());

    return () => {
      dispatch(clearMenuDetails());
      dispatch(closed());
    };
  }, []);

  const handleIsMenuCheck = (formRenderProps: FormRenderProps) => {
    const is_menu = formRenderProps.valueGetter("is_menu");
    if (is_menu === true) {
      formRenderProps.onChange("under_menu_id", {
        value: null,
      });
    }
  };

  const handleSubmit = async (values: any) => {
    try {
      if (!menu_guid) {
        const insertPayload = {
          menu_name: values?.menu_name ? values?.menu_name : "",
          menu_key: values?.menu_key ? values?.menu_key : "",
          is_menu: values?.is_menu ? values?.is_menu : false,
          under_menu_id: values?.under_menu_id ? values?.under_menu_id : null,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(createMenu(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearMenuDetails());
          dispatch(getAllMenus());
        }
      } else {
        const editPayload = {
          menu_guid: menu_guid,
          menu_name: values?.menu_name ? values?.menu_name : "",
          menu_key: values?.menu_key ? values?.menu_key : "",
          is_menu: values?.is_menu ? values?.is_menu : false,
          under_menu_id: values?.under_menu_id ? values?.under_menu_id : null,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(updateMenu(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearMenuDetails());
          dispatch(getAllMenus());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };
  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={MenuDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "17px 0 8px",
              }}
            >
              <Typography.h4 className="m-0">
                {menu_guid ? "Update Menu" : "Create Menu"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="menu_name"
                label="Menu Name"
                component={FormTextField}
                validator={requiredValidator}
                astrike={true}
                placeholder={"i.e. Dashboard"}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="menu_key"
                label="Key"
                component={FormTextField}
                validator={requiredValidator}
                astrike={true}
                placeholder={"i.e. dashboard"}
              />
            </GridLayoutItem>
            <GridLayoutItem
              style={{
                width: "100%",
                fontWeight: 700,
                padding: "20px 0 10px",
              }}
            >
              <Field
                name="is_menu"
                label="Is Main Menu"
                maxLength="100"
                component={FormCheckbox}
                onChange={() => handleIsMenuCheck(formRenderProps)}
              />
            </GridLayoutItem>
            {formRenderProps.valueGetter("is_menu") === false && (
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"under_menu_id"}
                  name={"under_menu_id"}
                  label={"Main Menu"}
                  placeholder={"Main Menu"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  astrike={true}
                  disabled={
                    formRenderProps.valueGetter("is_menu") === true
                      ? true
                      : false
                  }
                  options={MenuSubMenuList?.map((menu: IMenuSubMenuList) => {
                    return {
                      value: menu?.id,
                      label: menu?.menu_name,
                    };
                  })}
                />
              </GridLayoutItem>
            )}
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="isactive"
                label="Status"
                component={FormSelectionField}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status?.value,
                    label: status?.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "end",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={!menu_guid ? "Create" : "Update"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />

              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
          {/* <PaxCountChangeWatcher formRenderProps={formRenderProps} /> */}
        </FormElement>
      )}
    />
  );
};

export default CreateMenu;
