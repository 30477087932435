import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILeadType, ILeadTypeState } from "./leadtypeModel";
import {
  createLeadType,
  deleteLeadType,
  getAllActiveLeadType,
  getAllLeadType,
  getLeadTypeByID,
  leadTypeActiveInactive,
  updateLeadType,
} from "./services/leadtype.services";

const initialState: ILeadTypeState = {
  loading: false,
  formLoading: false,
  error: "",
  LeadTypeList: [],
  LeadTypeID: null,
  LeadTypeDetail: {
    lead_type: "",
    isactive: 1,
  },
};

const leadtypeSlice = createSlice({
  name: "leadtype",
  initialState,
  reducers: {
    clearLeadTypeDetails: (state) => {
      state.LeadTypeDetail = initialState.LeadTypeDetail;
    },
    setLeadTypeID: (state, action) => {
      state.LeadTypeID = action.payload;
    },
    clearLeadTypeID: (state) => {
      state.LeadTypeID = initialState.LeadTypeID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllLeadType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllLeadType.fulfilled,
      (state, action: PayloadAction<ILeadType[]>) => {
        state.loading = false;
        state.LeadTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllLeadType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.LeadTypeList = [];
    });
    builder.addCase(getAllActiveLeadType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveLeadType.fulfilled,
      (state, action: PayloadAction<ILeadType[]>) => {
        state.loading = false;
        state.LeadTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveLeadType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.LeadTypeList = [];
    });
    builder.addCase(createLeadType.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createLeadType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createLeadType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateLeadType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateLeadType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateLeadType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteLeadType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteLeadType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteLeadType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(leadTypeActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(leadTypeActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(leadTypeActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getLeadTypeByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getLeadTypeByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.LeadTypeDetail = action.payload;
    });
    builder.addCase(getLeadTypeByID.rejected, (state, action) => {
      state.formLoading = false;
      state.LeadTypeDetail = initialState.LeadTypeDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearLeadTypeDetails, setLeadTypeID, clearLeadTypeID } =
  leadtypeSlice.actions;
export default leadtypeSlice.reducer;
