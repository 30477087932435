import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ILeadType } from "../leadtypeModel";

export const getAllLeadType = createAsyncThunk(
  "LeadType/FindAllLeadType",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadType/FindAllLeadType`,
        {
          LeadType: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching LeadType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveLeadType = createAsyncThunk(
  "LeadType/FindAllActiveLeadType",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/LeadType/FindAllActiveLeadType`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching LeadType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createLeadType = createAsyncThunk(
  "LeadType/InsertLeadType",
  async (LeadTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadType/InsertLeadType`,
        LeadTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating LeadType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateLeadType = createAsyncThunk(
  "LeadType/UpdateLeadType",
  async (LeadTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadType/UpdateLeadType`,
        LeadTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating LeadType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getLeadTypeByID = createAsyncThunk(
  "LeadType/FindByIDLeadType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadType/FindByIDLeadType`,
        { lead_type_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        lead_type_guid: result.lead_type_guid,
        lead_type: result.lead_type,

        isactive: result.isactive === true ? 1 : 2,
      } as ILeadType;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching LeadType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const leadTypeActiveInactive = createAsyncThunk(
  "LeadType/ActiveInActiveLeadType",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadType/ActiveInActiveLeadType`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive LeadType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteLeadType = createAsyncThunk(
  "LeadType/DeleteLeadType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadType/DeleteLeadType`,
        { lead_type_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting LeadType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
