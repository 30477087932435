import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPort, IPortInitialstate } from "./portModel";
import {
  createPort,
  deletePort,
  getAllActivePort,
  getAllPort,
  getPortByID,
  portActiveInactive,
  updatePort,
} from "./services/port.services";

const initialState: IPortInitialstate = {
  loading: false,
  formLoading: false,
  error: "",
  PortList: [],
  PortID: null,
  PortDetail: {
    sector_id: null,
    port_name: "",
    isactive: 1,
  },
};

const portSlice = createSlice({
  name: "port",
  initialState,
  reducers: {
    clearPortDetails: (state) => {
      state.PortDetail = initialState.PortDetail;
    },
    setPortID: (state, action) => {
      state.PortID = action.payload;
    },
    clearPortID: (state) => {
      state.PortID = initialState.PortID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPort.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllPort.fulfilled,
      (state, action: PayloadAction<IPort[]>) => {
        state.loading = false;
        state.PortList = action.payload || [];
      }
    );
    builder.addCase(getAllPort.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PortList = [];
    });
    builder.addCase(createPort.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createPort.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createPort.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updatePort.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updatePort.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updatePort.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deletePort.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deletePort.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePort.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getPortByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getPortByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.PortDetail = action.payload;
    });
    builder.addCase(getPortByID.rejected, (state, action) => {
      state.formLoading = false;
      state.PortDetail = initialState.PortDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActivePort.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActivePort.fulfilled,
      (state, action: PayloadAction<IPort[]>) => {
        state.loading = false;
        state.PortList = action.payload || [];
      }
    );
    builder.addCase(getAllActivePort.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PortList = [];
    });
    builder.addCase(portActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(portActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(portActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearPortDetails, setPortID, clearPortID } = portSlice.actions;
export default portSlice.reducer;
