import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ILeadStatus } from "../leadstatusModel";


export const getAllLeadStatus = createAsyncThunk(
  "LeadStatus/FindAllLeadStatus",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadStatus/FindAllLeadStatus`,
        {
          LeadStatus: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching LeadStatus:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveLeadStatus = createAsyncThunk(
  "LeadStatus/FindAllActiveLeadStatus",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/LeadStatus/FindAllActiveLeadStatus`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching LeadStatus:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createLeadStatus = createAsyncThunk(
  "LeadStatus/InsertLeadStatus",
  async (LeadStatusData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadStatus/InsertLeadStatus`,
        LeadStatusData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating LeadStatus:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateLeadStatus = createAsyncThunk(
  "LeadStatus/UpdateLeadStatus",
  async (LeadStatusData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadStatus/UpdateLeadStatus`,
        LeadStatusData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating LeadStatus:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getLeadStatusByID = createAsyncThunk(
  "LeadStatus/FindByIDLeadStatus",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadStatus/FindByIDLeadStatus`,
        { lead_status_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        lead_status_guid: result.lead_status_guid,
        lead_status: result.lead_status,

        isactive: result.isactive === true ? 1 : 2,
      } as ILeadStatus;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching LeadStatus:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const leadStatusActiveInactive = createAsyncThunk(
  "LeadStatus/ActiveInActiveLeadStatus",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadStatus/ActiveInActiveLeadStatus`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive LeadStatus:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteLeadStatus = createAsyncThunk(
  "LeadStatus/DeleteLeadStatus",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadStatus/DeleteLeadStatus`,
        { lead_status_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting LeadStatus:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
