import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ILeadSource } from "../leadsourceModel";

export const getAllLeadSource = createAsyncThunk(
  "LeadSource/FindAllLeadSource",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadSource/FindAllLeadSource`,
        {
          LeadSource: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching LeadSource:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveLeadSource = createAsyncThunk(
  "LeadSource/FindAllActiveLeadSource",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/LeadSource/FindAllActiveLeadSource`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching LeadSource:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createLeadSource = createAsyncThunk(
  "LeadSource/InsertLeadSource",
  async (LeadSourceData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadSource/InsertLeadSource`,
        LeadSourceData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating LeadSource:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateLeadSource = createAsyncThunk(
  "LeadSource/UpdateLeadSource",
  async (LeadSourceData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadSource/UpdateLeadSource`,
        LeadSourceData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating LeadSource:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getLeadSourceByID = createAsyncThunk(
  "LeadSource/FindByIDLeadSource",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadSource/FindByIDLeadSource`,
        { lead_source_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        lead_source_guid: result.lead_source_guid,
        lead_source: result.lead_source,

        isactive: result.isactive === true ? 1 : 2,
      } as ILeadSource;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching LeadSource:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const leadSourceActiveInactive = createAsyncThunk(
  "LeadSource/ActiveInActiveLeadSource",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadSource/ActiveInActiveLeadSource`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive LeadSource:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteLeadSource = createAsyncThunk(
  "LeadSource/DeleteLeadSource",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadSource/DeleteLeadSource`,
        { lead_source_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Source:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
